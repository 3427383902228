import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'api/jobSetting',
    method: 'post',
    data
  })
}

export function del(ids) {
  return request({
    url: 'api/jobSetting/',
    method: 'delete',
    data: ids
  })
}

export function edit(data) {
  return request({
    url: 'api/jobSetting',
    method: 'put',
    data
  })
}

export function downloadPdf(id) {
    return request({
        url: 'api/jobSetting/pdf/'+id,
        method: 'get',
        responseType: 'blob'
    })
}

export function getOption(params) {
    return request({
        url: 'api/jobSetting/getOption',
        method: 'get',
        params
    })
}

export function getById(id) {
    return request({
        url: 'api/jobSetting/'+id,
        method: 'get',
    })
}



export default { add, edit, del ,downloadPdf,getOption,getById}
