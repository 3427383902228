<template>
  <div>
    <van-form ref="form" style="margin-bottom:65px">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.jobName" placeholder="请填写岗位名称" :required="true" label="岗位名称" :rules="[{ required: true  } ]" />
        <custom-select v-model="form.jobSelect" label="岗位类型" placeholder="请选择岗位类型" :required="true" :columns="statusDict" />
        <custom-select ref="schoolYear" v-model="form.schoolYear" :required="true" placeholder="请选择申请学年" label="申请学年" :columns="dict.xj_nj" />
        <custom-select ref="schoolTerm" v-model="form.schoolTerm" :required="true" placeholder="请选择申请学期" label="申请学期" :columns="dict.xj_xqgl" />
        <van-field v-model="form.unitName" s placeholder="请填写申请单位" :required="true" label="申请单位" :rules="[{ required: true  } ]" />
        <van-field v-model="form.deptment" placeholder="请输入用工科室" label="用工科室" :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="form.contactName" placeholder="请输入联系人" label="联系人" :required="true" :rules="[{ required: true  } ]" />
        <custom-radio v-model="form.jobType" label="岗位类型" placeholder="请选择岗位类型" :required="true" :columns="dict.daily_job_type" />
        <van-field v-model="form.contactPhone" placeholder="请输入联系电话" label="联系电话" :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="form.jobDemand" placeholder="请输入岗位需求人数" label="岗位需求人数" :required="true" :rules="[{ required: true  } ]" />
        <custom-date-picker required ref="workStartAt" v-model="form.workStartAt" label="岗位开始时间" placeholder="点击选择岗位开始时间" />
        <custom-date-picker required ref="workEndAt" v-model="form.workEndAt" label="岗位结束时间" placeholder="点击选择岗位结束日期" />
        <van-field v-model="form.workPlace" placeholder="请填写工作地点（具体到房间号）" label="工作地点" :required="true" :rules="[{ required: true  } ]" />
        <van-field v-model="form.workContent" placeholder="请输入工作内容" label="工作内容" rows="3" class="textarea-style" :required="true" :rules="[{ required: true  } ]" type="textarea" maxlength="300" show-word-limit />
        <van-field v-model="form.deptOpinion" placeholder="请输入部门意见" label="部门意见" rows="3" class="textarea-style" :rules="[{ required: true  } ]" type="textarea" maxlength="300" show-word-limit />
      </van-cell-group>

      <div class="submit-button  shadow-style">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit">提 交</van-button>
        </div>
      </div>
    </van-form>
  </div>
</template>
<script>
import crudJobSetting from '@/api/modules/daily/jobSetting'
import { validCustom } from "@/utils/index";
import { Dialog } from "vant";
import { tagStatusType, getTerm, parseTime } from '@/utils/index'
import { mapGetters } from "vuex";

export default {
  dicts: ['xj_nj', "xj_xqgl", "daily_job_type", "xj_shzt"],
  name: "JobName",
  data() {
    return {
      customRuleFileds: ["schoolYear", "schoolTerm", "workStartAt", "workEndAt"],
      form: {
        id: null,
        jobName: null,
        unitNo: null,
        jobSelect: "校内教学助理",
        unitName: null,
        deptment: null,
        contactName: null,
        contactPhone: null,
        jobType: '1',
        jobDemand: null,
        workStartAt: null,
        workEndAt: null,
        workPlace: null,
        workContent: null,
        schoolYear: null,
        schoolTerm: null,
        deptOpinion: null,
      },
      statusDict: [{
        value: "校内教学助理", label: "校内教学助理"
      },
      {
        value: "科研助理", label: "科研助理"
      },
      {
        value: "行政管理助理", label: "行政管理助理"
      },
      {
        value: "学校公共服务", label: "学校公共服务"
      }],
      loading: false,
    }
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      crudJobSetting.getById(this.$route.query.id).then((res) => {
        this.$nextTick(() => {
          this.form = { ...res }
          let pattern = /[\(|（].*[\)|）]$/
          let content = pattern.exec(res.jobName)
          if (res.jobName && content) {
            let select = this.form.jobName.substring(content.index, this.form.jobName.length)
            this.form.jobSelect = select.includes('校内教学助理')
              ? '校内教学助理'
              : select.includes('科研助理')
                ? '科研助理'
                : select.includes('行政管理助理')
                  ? '行政管理助理'
                  : select.includes('学校公共服务')
                    ? '学校公共服务'
                    : null
          }
        });

      })
    } else {
      let form = this.form
      form.unitNo = this.user.dept.id
      form.unitName = this.user.dept.name
      let termMap = getTerm(
        this.dict.xj_xqgl,
        parseInt((new Date().getMonth() + 1).toString())
      )
      form.schoolTerm = termMap.xqCode
      form.schoolYear = new Date().getFullYear().toString()
    }
  },
  methods: {
    onClickLeft() {
      this.form = {
        id: null,
        jobName: null,
        unitNo: null,
        jobSelect: "校内教学助理",
        unitName: null,
        deptment: null,
        contactName: null,
        contactPhone: null,
        jobType: '1',
        jobDemand: null,
        workStartAt: null,
        workEndAt: null,
        workPlace: null,
        workContent: null,
        schoolYear: null,
        schoolTerm: null,
        deptOpinion: null,
      }
      this.$router.go(-1)
    },
    doSubmit() {
      // 提交
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            // 验证通过数据提交
            Dialog.confirm({
              title: "提示",
              message: "你确定要进行提交操作吗？",
            })
              .then(() => {
                this.toSave(this.form);
              })
              .catch(() => {
                // on cancel
              });
          } else {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
    toSave(form) {
      let pattern = /[\(|（].*[\)|）]$/
      let content = pattern.exec(form.jobName)
      if (form.jobName && content) {
        let jobName1 = form.jobName.substring(0, content.index)
        let select = form.jobName.substring(content.index, form.jobName.length)
        form.jobSelect = select.includes('校内教学助理')
          ? '校内教学助理'
          : select.includes('科研助理')
            ? '科研助理'
            : select.includes('行政管理助理')
              ? '行政管理助理'
              : select.includes('学校公共服务')
                ? '学校公共服务'
                : null
        form.jobName = jobName1
      }
      this.loading = true;
      if (this.$route.query && this.$route.query.id) {
        crudJobSetting.edit(form)
          .then((res) => {
            this.loading = false;
            this.$notify({
              type: "success",
              message: "操作成功",
              duration: 2000,
            });
            this.onClickLeft();
          })
          .catch((e) => {
            this.loading = false;
          });
      } else {
        crudJobSetting.add(form)
          .then((res) => {
            this.loading = false;
            this.$notify({
              type: "success",
              message: "操作成功",
              duration: 2000,
            });
            this.onClickLeft();
          })
          .catch((e) => {
            this.loading = false;
          });
      }

    },
  },
}
</script>
<style lang="scss" scoped>
.van-field__label {
  width: 110px;
}
.textarea-style {
  height: 100% !important;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
</style>